<template>
  <div class="calendarDetail" :key="$route.fullpath" @click="listFlag = false">
    <div class="bread">
      <div class="nav-box">
        <router-link to="/pages/index/index?columnId=452" class="nav-title">路演</router-link>
        <span>&gt;</span>
        <span class="nav-title">企业中心</span>
      </div>
    </div>
    <div class="company-search">
      <div class="cpmpany-msg">
        <span class="company-name">{{
          detailDatas.shortName ? detailDatas.shortName : detailDatas.name
        }}</span>
        <span class="company-code" v-if="detailDatas.stockCode">{{
          detailDatas.stockCode
        }}</span>
        <span
          class="attestation-icon"
          v-if="detailDatas.attestationFlag === '已认证'"
        ></span>
        <p
          class="is-collect"
          @click="collectCompany(detailDatas)"
          :class="detailDatas.attention ? 'collected' : ''"
        >
          <i></i><span>{{ detailDatas.attention ? "关注" : "未关注" }}</span>
        </p>
      </div>
      <div class="search-info">
        <!-- <span class="search-tip">切换公司</span>
        <input v-model="searchTxt" class="search-text" type="text" placeholder="请输入公司名称或代码"/> -->
        <div class="ask-company">
          <span class="enter-kind">切换公司</span>
          <div class="enter-company-box">
            <input
              class="enter-company"
              type="text"
              v-model="companyName"
              @click.stop="listFlag = true"
              @focus="companyFocus"
              @blur="companyBlur"
              :placeholder="showCompany ? '' : '请输入公司名或股票代码'"
            />
            <p
              class="show-company"
              :class="companyName || hideCompany ? 'has-value' : ''"
            >
              {{ showCompany }}
            </p>
          </div>
          <ul class="company-list" v-show="listFlag" ref="companyList">
            <li
              v-for="item in companyList"
              :key="item.id"
              @click="chooseCompany(item)"
            >
              {{ item.name }}{{ item.stockCode }}
            </li>
          </ul>
        </div>
        <button class="search-btn" @click="changeCompany">搜索</button>
      </div>
    </div>
    <div class="roadshow-type">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        class="roadshow-type-item"
        :class="{ 'item-active': form.name === item.name }"
        @click="checkTab(index, item)"
      >
        <span class="roadshow-type-item-type">
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="secondary-menu" v-if="form.name === '员工情况' || form.name === '管理层研究'">
      <div
        v-for="(item, index) in tabList1"
        :key="index"
        class="menu-item"
        :class="{ 'item-active': form.url === item.url }"
        @click="checkTab1(item)"
      >
        <span>
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="luyan-list" v-if="form.name == '路演'">
      <template v-if="liveList && liveList.length > 0">
        <div
          class="video-item"
          v-for="(item, index) in liveList"
          :key="item.id"
        >
          <div class="video-item-content">
            <!-- <router-link> -->
            <div class="img_box" @click="luyanDetail(item.id)">
              <img
                :src="item.thumb"
                alt=""
                @error="item.error = true"
                v-if="!item.error"
              />
              <img src="~@/assets/img/indexImg/itembg.png" alt="" v-else />
              <!-- <i class="img_icon"></i> -->
              <em class="tip">{{
                getItemType(item.templateId, item.publishedTime)
              }}</em>
            </div>
            <!-- </router-link> -->
            <div class="item-info">
              <!-- <router-link> -->
              <h2 @click="luyanDetail(item.id)">{{ item.title }}</h2>
              <!-- </router-link> -->
              <div class="item-footnote">
                <div class="footnote-scoure">
                  <span class="footnote-company-name">
                    <span class="footnote-company-nameData">{{
                      item.officeName
                    }}</span>
                    <template v-if="item.officeCode">
                      （<span class="footnote-company-nameData">{{
                        item.officeCode
                      }}</span
                      >）
                    </template>
                  </span>
                  <span
                    :class="
                      item.collectStatus
                        ? 'collect-start-active'
                        : 'collect-start'
                    "
                    @click="collectRold(item.id, index)"
                  ></span>
                </div>
                <div class="footnote-timer">{{ item.publishedTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <NoData v-else />
      <p
        class="loading-more"
        v-if="!finished && !loadMore && liveList.length && pageNo >= 2"
        @click="getMore"
      >
        加载更多
      </p>
      <p class="load-finish" v-if="finished && liveList.length && pageNo > 2">
        加载完毕
      </p>
      <p class="loading-data" v-if="loadMore && liveList.length">
        <img src="@/assets/img/article_list/loading.gif" />加载中...
      </p>
    </div>
    <div v-else-if="this.detailDatas.type == '上市公司'">
      <SupportBrowser/>
      <Investors :id="$route.query.id" v-if="form.name === '投资者问答'" />
      <!-- 写三个iframe的原因是在ie下改变iframe的src，iframe里面的内容不会更新 -->
      <div v-show="!showIframe && form.name !== '投资者问答'" class="loading-box">
        <p class="loading-iframe"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
      </div>
      <div class="iframe-box" v-show="showIframe && form.name !== '投资者问答'">
        <iframe
          v-if="form.url && iframeShow"
          id="calendar"
          class="calendar"
          :src="`${pageDomain}/jyzd/thematic/#/thematic/JYF9/${detailDatas.stockCode}.${publicPlace}/${form.url}?themeType=light&token=${$store.state.accessToken}`"
          frameborder="0"
          scrolling="no"
          name="announce"
          @load="setIframe(form.url)"
        ></iframe>
      </div>
    </div>
    <Investors :id="$route.query.id" v-else-if="form.name === '投资者问答'" />

    <div v-else-if="tabContent" class="tab-content" v-html="tabContent"></div>
    <NoData v-else />
  </div>
</template>
<script>
import "@/assets/css/load_more.less";
import "@/assets/css/calendar.less";
import { setCatalogTree } from "@/utils/store-data";
import { companySearch } from '@/api/investors'
import { getCompanyDetails,businessLive,getCompanyList } from "@/api/calendar"
import { getRoldTabList,collectRold } from "@/api/rold";
import NoData from '@/components/NoData'
import SupportBrowser from "@/components/SupportBrowser.vue"
import Investors from "./components/investors.vue"
import $ from "jquery";
export default {
  components: { NoData, SupportBrowser, Investors },
  data() {
    return {
      timer: '',
      timerNew: '',
      // url: '',
      // name: '',
      domHeight: "",
      detailDatas: {},
      tabList: [],
      kindList: [],
      form: {
        templateId: "1",
        name: '路演',
        url: "",
      },
      templateIndex: 1,
      searchTxt: '',
      detailId: this.$route.query.id,
      pageNo: 1,
      pageSize: 8,
      loading: false,
      finished: false,
      loadMore: false,
      liveList: [],
      tabContent: '',
      loadFlag: false,
      itemId: '',
      companyName: '',
      showCompany: '',
      hideCompany: false,
      // isClear: false,
      listFlag: false,
      companyList: [],
      // selectList: [],
      publicPlace: 'SZ',
      // timeout: null,
      companyPageNo: 1,
      companyPageSize: 10,
      totalCompany: 0,
      showIframe: false,
      iframeShow: false,
      tabList1: []
    };
  },
  computed:{
    pageDomain:function(){
      return window.location.origin
    }
  },
  beforeRouteUpdate(to,from,next){
    next()
  },
  watch: {
    companyName(){
      // if(this.isClear){
        // this.listFlag = true
        this.companyPageNo = 1;
        this.companySearch();
        // var newLists = this.selectList.filter(item=>{
        //   return item.stockCode.indexOf(newVal)>=0||item.name.indexOf(newVal)>=0
        // })
        // if(newLists.length>0){
        //   this.companyList = newLists;
        // }else{
        //   this.companyList = [{id:0,name:'暂无数据',stockCode:'',getCode: ''}]
        // }
      // }
    },
  },
  created() {
    setCatalogTree(this.$store);
    this.hiddenLeft();
    this.getCompanyDetails();
    this.businessLive();
    this.loadSecond();
    this.getRoldTabList();
  },
  beforeRouteLeave(to, from, next) {
    window.clearInterval(this.timer)
    window.clearInterval(this.timerNew)
    window.removeEventListener('scroll', this.scrollFns);
    next();
  },
  mounted(){
    window.addEventListener('scroll', this.scrollFns);
    this.companySearch();
    this.checkScroll();
  },
  methods: {
    // 路演详情
    luyanDetail(id){
      var detailUrl = this.$router.resolve({path:'/pages/luyan-page/luyanDetails',query:{id:id,columnId:452}});
      window.open(detailUrl.href,'_blank')
    },
    // 提问的公司列表
    companySearch(){
      companySearch({pageNo:this.companyPageNo,pageSize:this.companyPageSize,name:this.companyName}).then(res=>{
        // var newList = [];
        this.totalCompany = res.count;
        if(this.companyPageNo == 1){
          this.companyList = []
        }
        if(res.data&&res.data.length>0){
          for(var i in res.data){
            this.companyList.push({id:res.data[i].id,name:res.data[i].name,stockCode:res.data[i].stockCode?'('+res.data[i].stockCode+')':'',getCode: res.data[i].stockCode});
          }
        }else{
          this.companyList = [{id:0,name:'暂无数据',stockCode:'',getCode: ''}]
        }
        // this.companyList = newList;
        // this.selectList = this.companyList;
      }).catch(()=>{})
    },
    lowEnough() {
      var companyBox = this.$refs.companyList
      if (!companyBox) {
        return false
      }
      var pageHeight = companyBox.scrollHeight;
      var viewportHeight = companyBox.clientHeight;
      var scrollHeight = companyBox.scrollTop;
      return pageHeight - viewportHeight - scrollHeight < 40 && this.listFlag && this.companyList.length<this.totalCompany;
    },
    doSomething() {
      this.companyPageNo++;
      this.companySearch();
      this.pollScroll(); //继续循环
    },
    checkScroll() {
      if (!this.lowEnough()){
        return this.pollScroll();
      }
      setTimeout(this.doSomething, 100);
    },
    pollScroll() {
      setTimeout(this.checkScroll, 200);
    },
    // 公司获取焦点
    companyFocus(){
      this.hideCompany = true;
      // this.isClear = true;
      // this.companyList = this.selectList;
    },
    // 公司失去焦点
    companyBlur(){
      var that = this;
      this.hideCompany = false;
      // this.isClear = false; // 用于搜索以后点击搜索出来的内容无效果的问题解决方法
      setTimeout(function(){
        that.listFlag = false;
        that.companyName = '';
      },200)
    },
    // 选择公司
    chooseCompany(item){
      if(item.id!=0){
        this.searchTxt = item.getCode;
        this.showCompany = item.name+item.stockCode;
        this.itemId=item.id
        this.listFlag = false;
        this.companyName = '';
        // this.companyList = this.selectList;
      }else{
        this.listFlag = true;
      }
    },
    // 切换公司
    changeCompany(){
      // if(this.searchTxt && this.searchTxt.trim() != ''){
      //   var changeData = {
      //     pageNo: 1,
      //     pageSize: 8,
      //     nameAndCode: this.searchTxt,
      //     industry: '',
      //     address: ''
      //   }
      //   getCompanyList(changeData).then(res=>{
      //     this.$router.push({path: '/enterprise/deatil',query:{id:res.data[0].id}})
          
      //   }).catch(()=>{})
      // }
      if(this.itemId){
        var changeData = {
          pageNo: 1,
          pageSize: 8,
          id: this.itemId,
          industry: '',
          address: ''
        }
        getCompanyList(changeData).then(res=>{
          this.$router.push({path: '/enterprise/deatil',query:{id:res.data[0].id}})
          
        }).catch(()=>{})
      }
    },
    // 企业详情
    getCompanyDetails(){
      var detailData = {
        id: this.$route.query.stockCode ? '' : this.detailId,
        stockCode: this.$route.query.stockCode ? this.$route.query.stockCode : ''
      }
      getCompanyDetails(detailData).then(res=>{
        this.detailDatas = res.data.entity;
        if(res.data.entity.publicPlace == '深圳证券交易所'){
          this.publicPlace = 'SZ'
        }else{
          this.publicPlace = 'SH'
        }
        if(this.detailDatas.type == "上市公司"){
          this.tabList = [{
            id: "1",
            name: "路演",
          },
          {
            id: "2",
            name: "公司简介",
          },
          {
            id: "3",
            name: "证券简介",
          },
          {
            id: "4",
            name: "所属板块及指数",
          },
          {
            id: "6",
            name: "股本结构",
          },
          {

            id: "7",
            name: "员工情况",
          },
          {

            id: "8",
            name: "管理层研究",
          },
          {

            id: "9",
            name: "公司新闻",
          },
          {
            id: "10",
            name: "公司公告",
          },
          {
            id: "5",
            name: "投资者问答",
          }
          ]
        }else{
          var newTabList = res.data.property.map(item=>{
            return {id:item.id,name:item.name,content:item.content}
          })
          this.tabList = newTabList;
        }
      }).catch(()=>{})
    },
    collectCompany(item){
      // this.detailDatas.attention = !this.detailDatas.attention; 
      if(this.$store.state.token) {
        if(this.$store.state.token.indexOf('BC_') != -1){
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可收藏，请';
          // this.isLoginLink = true;
          this.$message({tipTxt:'企业用户不可收藏，请',isLink:true,loginTxt:'登录个人账户'});
        }else{
          collectRold({id:item.id,type:11}).then(res=>{
            console.log(res)
            item.attention = !item.attention;
          }).catch(()=>{})
        }
      } else {
        this.$router.push('/login')
      }
    },
    // 加载更多
    getMore(){
      this.loading = true;
      this.finished = false;
      this.loadMore = true;
      this.pageNo++;
      this.businessLive();
    },
    // 路演列表
    businessLive(){
      if(!this.loadFlag){
        this.loadFlag = true;
        var liveData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          businessId: this.detailId
        }
        businessLive(liveData).then(res=>{
          if(this.pageNo == 1){
            this.liveList = [];
          }
          if(res.data.data&&res.data.data.length>0){
            res.data.data.forEach(item => item.error = false)
            this.liveList = this.liveList.concat(res.data.data)
            if(this.liveList.length>=res.data.count){
              this.finished = true;
            }
          }
        }).catch(()=>{}).finally(()=>{
          this.loading = false;
          this.loadMore = false;
          this.loadFlag = false;
        })
      }
    },
    //获取路演列表类型列表
    getRoldTabList() {
      getRoldTabList().then(res => {
        this.kindList = res.data;
      })
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadFlag && that.pageNo < 2){
          that.scrollFns();
        }
        if(that.pageNo >= 2 || that.finished || (that.liveList && (!that.liveList.length || that.liveList.length < 8) )){
          window.clearInterval(loadData)
        }
      },100)
    },
    // 滚动事件
    scrollFns(){
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollH-clientH-toTop<322 && !this.loading && !this.finished && this.pageNo<2){
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.pageNo++;
        this.businessLive();
      }
    },
    getItemType(type, publishedTime) {
      if (new Date(publishedTime).getTime() > new Date().getTime()) return '活动预告'
      var data = this.kindList.filter(item => {
        return item.id == type
      })
      if(data.length) return data[0].name
    },
    //切换tab
    checkTab(index, data) {
      // if(!this.timeout){
      //   this.timeout = setTimeout(() => {
      //     this.timeout = null
      //   }, 500);
      this.showIframe = false
        if(this.detailDatas.type == "上市公司"){
          this.templateIndex = index + 1;
          let url;
          switch (data.id) {
            case "2":
              url = "114737";
              break;
            case "3":
              url = "2659";
              break;
            case "4":
              url = "2663";
              break;
            case "6":
              url = "2667";
              break;
            case "7":
              url = "107545";
              this.tabList1 = [{ name: '员工构成', url: '107545' }, { name: '员工人数与薪酬', url: '2669' }];
              break;
            case "8":
              url = "2671";
              this.tabList1 = [{ name: '现任董高监', url: '2671' }, { name: '历任董高监', url: '2673' }, { name: '高管持股及报酬', url: '2675' }];
              break;
            case "9":
              url = "2906";
              break;
            case "10":
              url = "2898";
              break;
          }
          Object.assign(this.form, {
            templateId: data.id,
            name: data.name,
            url: url,
          });
          this.iframeShow = false
          this.$nextTick(() => {
            this.iframeShow = true
          })
          if (data.id !== "1") {
            this.hiddenLeft();
          }else{
            window.clearInterval(this.timer);
            window.clearInterval(this.timerNew);
          }
        }else{
          Object.assign(this.form, {
            name: data.name,
            templateId: data.id,
          });
          if (data.id !== "1" && data.name != '路演') {
            var chooseContent = this.tabList.filter(item=>{
              return data.id == item.id;
            })
            console.log(chooseContent)
            if(chooseContent[0].content&&chooseContent[0].content!=''){
              this.tabContent = chooseContent[0].content;
              
            }else{
              // this.tabContent = `<div class="no_data">
              //                     <div class="no-rold-data">
              //                       <p class="no-rold-img"></p>
              //                       <p class="no-rold-tip">暂无更多内容</p>
              //                     </div>
              //                   </div>`
            }
          }
        }
      // }
    },
    checkTab1(item) {
      this.$set(this.form, 'url', item.url)
      this.iframeShow = false
      this.$nextTick(() => {
        this.iframeShow = true
      })
      this.hiddenLeft();
    },
    // 调整iframe的样式
    setIframe(url){
      let height = '1080px'
      if (url === '2906' || url === '2898') {
        height = '626px'
      } else if (url === '2671') {
        height = '1424px'
      } else if (url === '2659') {
        height = '509px'
      }
      var iframe = $("#calendar").prop("contentWindow");
      var iframe2 = iframe.document.body;
      var siderMenu = iframe2.querySelector(".siderMenu");
      var resizeControl = iframe2.querySelector('.resizeControl');
      $("#calendar").height(height); // 产品说固定死高度
      if(siderMenu){
        siderMenu.style.display = "none";
      }
      if(resizeControl){
        resizeControl.style.display = "none";
      }
    },
    hiddenLeft() {
      var that = this;
      // this.showIframe = false;
      that.timer = setInterval(function () {
        // calendar iframe
        var calendarEl = window.document.querySelector('#calendar');
        if (calendarEl && calendarEl.contentWindow && calendarEl.contentWindow.document && calendarEl.contentWindow.document.body) {
          var calendarElDocument = calendarEl.contentWindow.document;
          var toolbar = calendarElDocument.querySelector(".toolbar");
          if (toolbar) {
            toolbar.style.display = "none";
          }
          setTimeout(function() {
            that.showIframe = true;
          }, 2000)
          window.clearInterval(that.timer);
          that.timerNew = setInterval(function () {
            // iframeBoxEl iframe
            var iframeBoxEl = calendarElDocument.querySelector('#iframeBox');
            if (iframeBoxEl && iframeBoxEl.contentWindow && iframeBoxEl.contentWindow.document && iframeBoxEl.contentWindow.document.body) {
              var iframeBoxElDocument = iframeBoxEl.contentWindow.document;
              var searchBox = iframeBoxElDocument.querySelector(".searchBox");
              if (searchBox) {
                searchBox.style.display = "none";
              }
              var navtip = iframeBoxElDocument.querySelector(".navtip");
              if (navtip) {
                navtip.style.display = "none";
              }
              window.clearInterval(that.timerNew);
            }
          }, 100)
        }
      },100)
    },
    //收藏
    collectRold(id, index) {
      if(this.$store.state.token) {
        if(this.$store.state.token.indexOf('BC_') != -1){
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可收藏，请';
          // this.isLoginLink = true;
          this.$message({tipTxt:'企业用户不可收藏，请',isLink:true,loginTxt:'登录个人账户'});
        }else{
          collectRold({
            id,
            type: '10'
          }).then(res => {
            if(res.code == 0) {
              this.liveList[index].collectStatus = !this.liveList[index].collectStatus
            }
          })
        }
      } else {
        this.$router.push('/login')
      }
    },
  },
};
</script>
<style lang="less">
.iframe-box{
  margin: 0 auto;
  width: 1200px;
}
.loading-box{
  position: relative;
  min-height: calc(100vh - 240px - 350px - 40px);
  .loading-iframe{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
    color: #999999;
    >img{
      width: auto;
      height: 19px;
      display: inline-block;
    }
  }
}
.no_data {
  .no-rold-data {
    margin: 0 auto;
    padding: 114px 0 232px;
    .no-rold-img {
      width: 122px;
      height: 96px;
      background: url("~@/assets/img/indexImg/nodata.svg") no-repeat;
      background-size: 100% auto;
      margin: 0 auto;
    }
    .no-rold-tip {
      margin-top: 9px;
      text-align: center;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
</style>
